import React from "react";
import "@/pages/BackgroundSection/Partners/Partners.css";

// partners logos
import Arshexa from "@/img/Partners/artshexa.png";
import Gallery2 from "@/img/Partners/gallery2.png";
import Johyun from "@/img/Partners/johyun.png";
import Pbg from "@/img/Partners/pbg.png";
import Ddp from "@/img/Partners/ddp.png";
import FingerLabs from "@/img/Partners/fingerlabs.png";
import NftGo from "@/img/Partners/nftgo.png";
import Beosin from "@/img/Partners/beosin.png";
import Web3sg from "@/img/Partners/web3sg.png";
import Carry from "@/img/Partners/carry.png";
import Neet from "@/img/Partners/neet.png";
import Chainlink from "@/img/Partners/chainlink.png";
import Contentos from "@/img/Partners/contentos.png";
import Crastonic from "@/img/Partners/crastonic.png";
import ScriptNetwork from "@/img/Partners/script-network.png";
import TokenInsight from "@/img/Partners/tokeninsight.png";
import Zeeper from "@/img/Partners/zeeper.png";
import Oip from "@/img/Partners/oip.png";
import ThreePM from "@/img/Partners/3PM.png";
import YK from "@/img/Partners/YK.png";
import Shinsung from "@/img/Partners/Shinsung.png";
import Aeon from "@/img/Partners/Aeon.png";
import DWF from "@/img/Partners/DWF.png";
import Kollector from "@/img/Partners/Kollector.png";
import Coin98 from "@/img/Partners/Coin98.png";
import Cryptodotcom from "@/img/Partners/Cryptodotcom.png";
import KODA from "@/img/Partners/KODA.png";
import Sei from "@/img/Partners/Sei.png";

export default function Partners(props) {
  const offset = props.offset;
  const pageInfo = props.pageInfo;
  const screenInfo = props.screenInfo;

  const sceneInfo = {
    page: {
      startY: pageInfo.startY + screenInfo.innerHeight * 1.7,
    },
  };

  return (
    <section
      className={
        offset >= sceneInfo.page.startY
          ? "partners-section translate-up"
          : "partners-section"
      }
    >
      <div className="roadmap-table">
        <div className="roadmap-title">
          <p className="lexend-reg">Partners</p>
          <p>
            We are making Digital Art Great Again with the following companies &
            projects
          </p>
        </div>
      </div>

      <div className="partners-contents">
        <div>
          <img src={Arshexa} alt="artshexa" />
        </div>
        <div>
          <img src={Gallery2} alt="gallery2" />
        </div>
        <div>
          <img src={Johyun} alt="Johyun" />
        </div>
        <div>
          <img src={Ddp} alt="ddp" />
        </div>
        <div>
          <img src={Cryptodotcom} alt="Cryptodotcom" />
        </div>
        <div>
          <img src={Chainlink} alt="chainlink" />
        </div>

        <div>
          <img src={Sei} alt="Sei" />
        </div>
        <div>
          <img src={Coin98} alt="Coin98" />
        </div>
        <div>
          <img src={Contentos} alt="Contentos" />
        </div>
        <div>
          <img src={Pbg} alt="Pbg" />
        </div>
        <div>
          <img src={TokenInsight} alt="token insight" />
        </div>
        <div>
          <img src={DWF} alt="DWF LABS" />
        </div>

        <div>
          <img src={Beosin} alt="Beosin" />
        </div>
        <div>
          <img src={Oip} alt="OIP" />
        </div>
        <div>
          <img src={Crastonic} alt="Crastonic" />
        </div>
        <div>
          <img src={ScriptNetwork} alt="Script network" />
        </div>
        <div>
          <img src={Web3sg} alt="web3sg" />
        </div>
        <div>
          <img src={NftGo} alt="NFTGO" />
        </div>

        <div>
          <img src={Neet} alt="neet" />
        </div>
        <div>
          <img src={YK} alt="YK" />
        </div>
        <div>
          <img src={ThreePM} alt="3PM" />
        </div>
        <div>
          <img src={Shinsung} alt="Shinsung" />
        </div>
        <div>
          <img src={Aeon} alt="Aeon" />
        </div>
        <div>
          <img src={Kollector} alt="Kollector" className="mobile-sm" />
        </div>

        <div>
          <img src={Carry} alt="carry" />
        </div>
        <div>
          <img src={Zeeper} alt="Zeeper" />
        </div>
        <div>
          <img src={KODA} alt="KODA" />
        </div>
        <div>
          <img src={FingerLabs} alt="finger labs" />
        </div>
      </div>
    </section>
  );
}
