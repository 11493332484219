import React from "react";
import "@/sections/artiside/artiside-mobile-textarea.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import Arrow from "components/Icons/Arrow";

function ArtisideMobileTextarea(props) {
  const isMobile = props.isMobile;
  const screenInfo = props.screenInfo;

  const section = {
    display: screenInfo.innerWidth <= 1280 ? "block" : "none",
    position: "absolute",
    top:
      screenInfo.innerWidth <= 1280 ? (isMobile ? "470px" : "778px") : "36px",
    width: "100%",
    padding: "0 20px",
    maxWidth: "560px",
  };

  const typography = {
    lineHeight: isMobile ? "20px" : "26px",
    letterSpacing: "1px",
    fontSize: isMobile ? "16px" : "18px",
    fontWeight: "400",
    maxWidth: isMobile
      ? "300px"
      : screenInfo.innerWidth <= 1280
      ? "538px"
      : "300px",
    color: "rgba(51, 51, 51, 1)",
  };

  return (
    <div style={{ ...section, marginTop: isMobile ? "5px" : "-60px" }}>
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        pagination={true}
        modules={[Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div
            className="mobile-textarea"
            style={{ ...typography, textAlign: "center" }}
          >
            <p style={{ marginTop: "10px" }}>
              Have you discovered <br className="mobile-only" />
              captivating artworks?
              <br />
              Have you come across an artist <br className="mobile-only" />
              with immense potential?
              <br />
              <br />
              If so, it's time to support them through <b>'Seeding.'</b>
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className="mobile-textarea"
            style={{ ...typography, textAlign: "center" }}
          >
            <p style={{ marginTop: "10px" }}>
              Seeding on Artiside goes <br className="mobile-only" />
              beyond a simple patronage <br className="mobile-only" />
              system. You can also earn <br className="mobile-only" />
              rewards while supporting artists.
              <br />
              <br />
              Join us on Artiside and
              <br />
              <b>let's Seed together!</b>
            </p>
          </div>
        </SwiperSlide>
      </Swiper>
      <div className="mobile-artiside-button-wrap">
        <a
          className="artiside-button"
          href="https://artiside.artdefinance.io/seeding/about"
          target="_blank"
          rel="noreferrer"
        >
          About Seeding program <Arrow />
        </a>
      </div>
    </div>
  );
}

export default ArtisideMobileTextarea;
