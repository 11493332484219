import { Dialog } from "@mui/material";
import "./MobileNavMenu.css";
import HamburgerMenu from "components/Icons/HamburgerMenu";
import Logo from "components/Icons/Logo";
import { ReactComponent as ArtisideLogo } from "@/img/NavBar/artiside_logo.svg";
import { ReactComponent as PartnersLogo } from "@/img/NavBar/partners_logo.svg";
import { ReactComponent as TheFluxLogo } from "@/img/NavBar/The_FLUX_logo.svg";

export default function MobileNavMenu(props) {
  const { open, onClick } = props;
  return (
    <Dialog
      fullScreen
      open={open}
      sx={{
        transition: "300ms",
        "@media (min-width: 801px)": { display: "none" },
      }}
    >
      <div
        className="mobile-nav-menu"
        style={{ display: open ? "flex" : "none" }}
      >
        <div className="mobile-nav-menu__top">
          <span style={{ width: 70 }}>
            <Logo color="black" />
          </span>
          <button onClick={onClick}>
            <HamburgerMenu open={open} color="#222222" />
          </button>
        </div>

        <div className="mobile-nav-menu__center">
          <ul>
            <li onClick={() => window.open("https://artiside.artdefinance.io")}>
              <ArtisideLogo />
            </li>
            <li onClick={() => window.open("https://theflux.artdefinance.io")}>
              <TheFluxLogo />
            </li>
            <li onClick={() => window.open("https://partners.artdefinance.io")}>
              <PartnersLogo />
            </li>
          </ul>
        </div>

        <div className="mobile-nav-menu__bottom">
          <h1>
            <a
              href="mailto:admin@artdefinance.io"
              style={{
                color: "#888888",
                fontSize: 16,
                letterSpacing: -0.16,
                textDecoration: "underline",
              }}
            >
              Admin@artdefinance.io
            </a>
          </h1>
          <p style={{ color: "#888888", fontSize: 14, fontWeight: 600 }}>
            © 2023. Art de Finance. All Rights Reserved
          </p>
        </div>
      </div>
    </Dialog>
  );
}
