import React from "react";
import "@/pages/BackgroundSection/Community/Community.css";
import { ReactComponent as Discord } from "@/img/SNSicon/discord.svg";
import { ReactComponent as Twitter } from "@/img/SNSicon/twitter.svg";
import { ReactComponent as Medium } from "@/img/SNSicon/medium.svg";
import { ReactComponent as Gitbook } from "@/img/SNSicon/gitbook.svg";
import CardWrapper from "@/pages/BackgroundSection/Community/CardWrapper";

export default function Community(props) {
  const offset = props.offset;
  const pageInfo = props.pageInfo;
  const screenInfo = props.screenInfo;

  const sceneInfo = {
    page: {
      startY: pageInfo.startY + screenInfo.innerHeight,
      endY: pageInfo.startY + pageInfo.height * 2,
    },
  };
  return (
    <section
      className={
        offset >= sceneInfo.page.startY && offset < sceneInfo.page.endY
          ? "community-section translate-up"
          : "community-section"
      }
    >
      <div className="roadmap-table">
        <div className="roadmap-title">
          <p className="lexend-reg">Community</p>
          <p className="community-subtitle">
            Join the community that leads <br />a global art market
          </p>
        </div>
      </div>

      <div className="community-btns">
        <button>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://discord.com/invite/art-de-finance"
          >
            <Discord className="sns-icon__discord" />
          </a>
        </button>
        <button>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/ArtdeFinance"
          >
            <Twitter className="sns-icon__twitter" />
          </a>
        </button>
        {/* <button>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/art_de_finance/"
          >
            <Insta className="sns-icon__insta" />
          </a>
        </button> */}

        <button>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://medium.com/@Art_de_Finance"
          >
            <Medium className="sns-icon__medium" />
          </a>
        </button>
        {/* <button>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://t.me/artdefinanceann"
          >
            <Telegram className="sns-icon__telegram" />
          </a>
        </button> */}
        {/* <button>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.youtube.com/@artdefinance"
          >
            <Youtube className="sns-icon__youtube" />
          </a>
        </button> */}
        <button>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://docs.artdefinance.io/"
          >
            <Gitbook className="sns-icon__gitbook" />
          </a>
        </button>
      </div>
      <CardWrapper />
    </section>
  );
}
