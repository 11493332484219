export default function Arrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="14"
      viewBox="0 0 9 14"
      fill="none"
    >
      <path d="M1 13L7 7L0.999999 1" stroke="#9BA1A8" strokeWidth="1.5" />
    </svg>
  );
}
