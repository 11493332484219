import React, { useEffect, useMemo, useState } from "react";
import { Card } from "@/components/Card/Card";
import { getLocalMediumPosts } from "data/LocalMediumData/getLocalMediumPosts";

export default function CardWrapper() {
  const [data, setData] = useState([]);
  const memoizedData = useMemo(() => getLocalMediumPosts(), []);

  useEffect(() => {
    setData(memoizedData);

    const mediumURL =
      "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@Art_de_Finance";

    fetch(mediumURL)
      .then((response) => {
        if (!response.ok) {
          throw new Error("HTTP error, status = " + response.status);
        }
        return response.json();
      })
      .then((data) => {
        let slicedData = data ? data.items.slice(0, 4) : memoizedData;

        slicedData = slicedData.map((item) => {
          const tempDiv = document.createElement("div");
          tempDiv.innerHTML = item.content;

          return {
            ...item,
            thumbnail: tempDiv.querySelector("img").getAttribute("src"),
          };
        });
        setData(slicedData);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div className="community-contents">
      <div className="card-wrapper card-wrapper-web card-wrapper-mob">
        <Card data={data} />
      </div>
    </div>
  );
}
