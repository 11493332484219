import React, { useEffect, useRef } from "react";
import "@/pages/ThreePages/common.css";
import "@/pages/ThreePages/NFTfi/NFTfi.css";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Splitting from "splitting";
import movie from "@/img/video/NFT-Fi.mp4";

gsap.registerPlugin(ScrollTrigger);

function NFTfi({ offset, refProp, pageInfo, isMobile, screenInfo }) {
  const textRef = useRef(null);

  const sceneInfo = {
    page: {
      startY: pageInfo.startY,
      endY: pageInfo.startY + pageInfo.height,
    },
  };

  useEffect(() => {
    const texts = Splitting({ target: textRef.current, by: "chars" });

    const timeline = gsap.timeline({
      default: { duration: 0.6, ease: "power2.inOut" },
      scrollTrigger: {
        trigger: textRef.current,
        start: sceneInfo.page.startY + screenInfo.innerHeight * 0.33,
        end: sceneInfo.page.startY + screenInfo.innerHeight * 0.98,
        toggleActions: "play none none reset",
        scrub: true,
      },
    });

    texts.forEach((text) => {
      const chars = text.chars;
      timeline.from(
        chars,
        { willChange: "opacity", opacity: 0.4, stagger: 0.03 },
        "-=0.55"
      );
      timeline.to(
        chars,
        { willChange: "opacity", opacity: 1, stagger: 0.03 },
        "-=0.55"
      );
    });

    return () => {
      timeline.kill();
    };
  }, [sceneInfo.page.startY, screenInfo.innerHeight]);

  return (
    <div
      className="section-wrapper section-wrapper__nftfi"
      ref={refProp}
      style={{
        position: "sticky",
        top: 0,
        left: 0,
        opacity:
          offset > sceneInfo.page.startY &&
          offset <= sceneInfo.page.startY + pageInfo.height
            ? 1
            : 0,
        zIndex:
          offset > sceneInfo.page.startY &&
          offset <= sceneInfo.page.startY + pageInfo.height
            ? 1
            : 0,
        transitionDelay: "opacity 1s",
      }}
    >
      <section className="page3-section page3-section__nftfi">
        <div className="page3-wrapper__nftfi">
          <div className="nft-text nft-title-text">
            <div
              className="title-section nft-effect"
              style={{ marginTop: isMobile ? "40px" : "0px" }}
            >
              <div
                className={
                  offset >= sceneInfo.page.startY &&
                  offset < sceneInfo.page.endY
                    ? "web-title title-effect title-effect__nftfi"
                    : "web-title"
                }
                style={{
                  width: "100%",
                  maxWidth: isMobile ? "500px" : "none",
                  margin: isMobile ? "-10px auto 0" : "0 auto",
                }}
              >
                <div className="text-top__nftfi">
                  <p
                    className="text-title text-title__nftfi"
                    style={{
                      color: "rgba(102, 102, 102, 1)",
                      fontSize: isMobile ? "14px" : "16px",
                      maxWidth: isMobile
                        ? "none"
                        : screenInfo.innerWidth <= 1280
                        ? "532px"
                        : "none",
                    }}
                  >
                    Point 2 &nbsp;
                    <span
                      style={{
                        color: "rgba(187, 187, 187, 1)",
                        fontSize: isMobile ? "16px" : "18px",
                      }}
                    >
                      NFT-Fi
                    </span>
                  </p>
                  <p
                    className="lexend-reg"
                    style={{ fontSize: isMobile ? "22px" : "40px" }}
                  >
                    Art Investment with
                  </p>
                </div>
                <div
                  className="text-bottom text-bottom__nftfi"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: isMobile ? "center" : "flex-start",
                    justifyContent: isMobile
                      ? "center"
                      : screenInfo.innerWidth <= 1280
                      ? "center"
                      : "flex-start",
                  }}
                >
                  <p
                    className="lexend-reg"
                    style={{
                      marginTop: "4px",
                    }}
                  >
                    {screenInfo.innerWidth > 1400 ? (
                      <span className="lexend-reg">
                        the aid of &nbsp;
                        <span className="text-accent lexend-med align-text__bottom">
                          Technology
                        </span>
                      </span>
                    ) : (
                      <span
                        className="lexend-reg"
                        style={{ fontSize: isMobile ? "22px" : "40px" }}
                      >
                        the aid of <br />
                      </span>
                    )}
                  </p>
                  <div
                    style={{
                      width: "100%",
                      display: screenInfo.innerWidth <= 1400 ? "flex" : "none",
                      justifyContent: "flex-end",
                    }}
                  >
                    <p
                      className="text-accent lexend-med"
                      style={{
                        fontSize: isMobile ? "45px" : "50px",
                        marginTop: isMobile ? "-10px" : "0px",
                      }}
                    >
                      Technology
                    </p>
                  </div>
                </div>
              </div>
              {/* 모바일 텍스트 */}
              {screenInfo.innerWidth <= 1400 ? (
                <div
                  className={
                    offset >= sceneInfo.page.startY &&
                    offset < sceneInfo.page.endY
                      ? "mobile-text mobile-text__nftfi title-effect title-effect__nftfi"
                      : "mobile-text mobile-text__nftfi"
                  }
                  style={{
                    marginTop: isMobile
                      ? "25px"
                      : screenInfo.innerWidth <= 1400
                      ? "40px"
                      : "90px",
                  }}
                >
                  <p>
                    Enticing investment strategies such as Staking and Lending
                    and,
                    <br />
                    where technological advantages from NFT can be infused with
                    <br />
                    &nbsp;the artwork. The profit creation from such will serve
                    as a
                    <br />
                    &nbsp;source of huge motivation for the users.
                  </p>
                </div>
              ) : null}
              {/* 웹 텍스트 */}
              <div
                className={
                  offset >= sceneInfo.page.startY &&
                  offset < sceneInfo.page.endY
                    ? "sub-text__nftfi sub-effect sub-effect__nftfi"
                    : "sub-text__nftfi"
                }
                style={{
                  marginTop: "20%",
                  display: isMobile ? "none" : "block",
                }}
              >
                <p style={{ width: 220 }} ref={textRef}>
                  Enticing investment strategies such as Staking and Lending,
                  where technological advantages from NFT can be infused with
                  the artwork. The profit creation from such will serve as a
                  source of huge motivation for the users.
                </p>
              </div>
            </div>
          </div>
          <div
            className={
              offset >= sceneInfo.page.startY && offset < sceneInfo.page.endY
                ? "page3-imgwrapper page3-imgwrapper__nftfi img-effect"
                : "page3-imgwrapper page3-imgwrapper__nftfi"
            }
          >
            <video
              className="nft-video"
              type="video/mp4"
              src={movie}
              autoPlay
              muted
              loop
              playsInline
            >
              <source src={movie} type="video/mp4" />
            </video>
          </div>
        </div>
      </section>
    </div>
  );
}

export default NFTfi;
