import React from "react";
import "@/pages/Error/Error.css";
import ErrorBG from "@/img/error/error-bg.png";
import { ReactComponent as Logo } from "@/img/error/logo.svg";
import ErrorFrame from "@/img/error/error-frame.png";
import { ReactComponent as ErrorIcon } from "@/img/error/error-icon.svg";
import { ReactComponent as FrameText } from "@/img/error/frame-text.svg";
import { ReactComponent as ErrorBtn } from "@/img/error/error-btn.svg";
import { ReactComponent as ErrorMsg } from "@/img/error/error-msg.svg";

function Error() {
  return (
    <section className="error-page">
      <div className="error-bg-image">
        <img src={ErrorBG} alt="에러 백그라운드" />
      </div>

      <div className="error-info">
        <div className="logo">
          <Logo />
        </div>
        <div className="error-frame">
          <img src={ErrorFrame} alt="액자 이미지" />
          <ErrorIcon className="error-frame-svg error-icon" />
          <FrameText className="error-frame-svg error-text" />
        </div>
        <div className="error-msg">
          <ErrorMsg />
        </div>
        <div className="error-btn">
          <a href="/">
            <ErrorBtn />
          </a>
        </div>
      </div>
    </section>
  );
}

export default Error;
