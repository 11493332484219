import { LOCAL_MEDIUM_RSS } from "./LocalMediumRSS";

export const getLocalMediumPosts = () => {
  const RAW_DATA = LOCAL_MEDIUM_RSS;

  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(RAW_DATA, "application/xml");
  const items = Array.from(xmlDoc.querySelectorAll("item")).slice(0, 4);

  let data = [];
  data = items.map((item) => {
    const contentEncoded =
      item.querySelector("content\\:encoded") || item.querySelector("encoded");

    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = contentEncoded.textContent;
    const thumbnail = tempDiv.querySelector("img").getAttribute("src");

    return {
      link: item.querySelector("link").textContent,
      title: item.querySelector("title").textContent,
      content: contentEncoded ? contentEncoded.textContent : "",
      pubDate: item.querySelector("pubDate").textContent,
      thumbnail,
    };
  });

  return data;
};
