import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "@/App.css";
import Navbar from "@/components/Navbar/Navbar";
import First from "@/pages/First/First";
import "@/pages/Main/Main.css";
import Background from "@/pages/BackgroundSection/Background";
import Texture from "@/img/common/texture.png";
import Experience from "@/pages/ThreePages/Experience/Experience";
import NFTfi from "@/pages/ThreePages/NFTfi/NFTfi";
import Contents from "@/pages/ThreePages/Contents/Contents";
import TopButton from "@/components/TopButton/TopButton";
import Error from "@/pages/Error/Error";
import Footer from "@/components/Footer/Footer";
import Artiside from "pages/Artiside/Artiside";

function App() {
  const [isMobile, setIsMobile] = useState(false);
  const [offset, setOffset] = useState(0);

  const [screenInfo, setScreenInfo] = useState({
    innerHeight: 0,
    innerWidth: 0,
  });

  const artisidePage = useRef(null);
  const page1 = useRef(null);
  const page2 = useRef(null);
  const page3 = useRef(null);
  const page4 = useRef(null);
  const page5 = useRef(null);
  const footer = useRef(null);

  const [pageInfo, setPageInfo] = useState({
    artisidePage: { height: 0, startY: 0 },
    page1: { height: 0, startY: 0 },
    page2: { height: 0, startY: 0 },
    page3: { height: 0, startY: 0 },
    page4: { height: 0, startY: 0 },
    page5: { height: 0, startY: 0 },
  });

  useEffect(() => {
    function getDimensions() {
      setScreenInfo({
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight,
      });
      setPageInfo({
        artisidePage: { height: artisidePage.current?.clientHeight, startY: 0 },
        page1: {
          height: page1.current?.clientHeight,
          startY: artisidePage.current?.clientHeight,
        },
        page2: {
          height: page2.current?.clientHeight,
          startY:
            artisidePage.current?.clientHeight + page1.current?.clientHeight,
        },
        page3: {
          height: page3.current?.clientHeight,
          startY:
            artisidePage.current?.clientHeight +
            page1.current?.clientHeight +
            page2.current?.clientHeight,
        },
        page4: {
          height: page4.current?.clientHeight,
          startY:
            artisidePage.current?.clientHeight +
            page1.current?.clientHeight +
            page2.current?.clientHeight +
            page3.current?.clientHeight,
        },
        page5: {
          height: page5.current?.clientHeight,
          startY:
            artisidePage.current?.clientHeight +
            page1.current?.clientHeight +
            page2.current?.clientHeight +
            page3.current?.clientHeight +
            page4.current?.clientHeight,
        },
      });
    }

    function handleScroll() {
      setOffset(window.scrollY);
      getDimensions();
    }

    function refreshPage() {
      window.location.reload();
    }

    window.addEventListener("load", getDimensions);
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", getDimensions);
    window.addEventListener("orientationchange", refreshPage);
    window.history.scrollRestoration = "manual";

    return () => {
      window.removeEventListener("load", getDimensions);
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", getDimensions);
      window.removeEventListener("orientationchange", refreshPage);
    };
  }, []);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");

    const handleResize = (e) => {
      setIsMobile(e.matches);
    };

    handleResize(mediaQuery);

    mediaQuery.addEventListener("change", handleResize);

    return () => mediaQuery.removeEventListener("change", handleResize);
  }, [isMobile]);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <div
              className="App"
              style={{
                backgroundImage: { Texture },
              }}
            >
              <Navbar
                offset={offset}
                pageInfo={pageInfo.artisidePage}
                isWhite={offset > pageInfo?.page1?.startY}
              />
              <Artiside
                offset={offset}
                refProp={artisidePage}
                pageInfo={pageInfo.artisidePage}
                screenInfo={screenInfo}
                isMobile={isMobile}
              />
              <First
                offset={offset}
                refProp={page1}
                pageInfo={pageInfo.page1}
                screenInfo={screenInfo}
                isMobile={isMobile}
              />
              {/* <Main
                offset={offset}
                refProp={page2}
                pageInfo={pageInfo.page2}
                screenInfo={screenInfo}
              /> */}
              <Experience
                offset={offset}
                refProp={page2}
                pageInfo={pageInfo.page2}
                isMobile={isMobile}
                screenInfo={screenInfo}
              />
              <NFTfi
                offset={offset}
                refProp={page3}
                pageInfo={pageInfo.page3}
                screenInfo={screenInfo}
                isMobile={isMobile}
              />
              <Contents
                offset={offset}
                refProp={page4}
                pageInfo={pageInfo.page4}
                screenInfo={screenInfo}
                isMobile={isMobile}
              />
              <Background
                offset={offset}
                refProp={page5}
                pageInfo={pageInfo.page5}
                screenInfo={screenInfo}
              />
              <TopButton />
              <Footer refProp={footer} />
            </div>
          }
        />
        <Route path="/*" element={<Error />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
