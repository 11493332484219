import React from "react";
import "@/pages/BackgroundSection/Faq/Faq.css";
import SimpleAccordion from "@/components/SimpleAccordion/SimpleAccordion";

export default function Faq(props) {
  const offset = props.offset;
  const pageInfo = props.pageInfo;
  const screenInfo = props.screenInfo;

  const sceneInfo = {
    page: {
      startY: pageInfo.startY + screenInfo.innerHeight * 1.5,
      endY: pageInfo.startY + pageInfo.height * 3,
    },
  };
  return (
    <section
      className={
        offset >= sceneInfo.page.startY && offset < sceneInfo.page.endY
          ? "qna-section translate-up"
          : "qna-section"
      }
    >
      <div className="roadmap-table">
        <div className="roadmap-title">
          <p className="lexend-reg">FAQ</p>
          <p>A few questions you might have</p>
        </div>
      </div>

      <div className="faq-contents">
        <SimpleAccordion />
      </div>
    </section>
  );
}
