import React, { useState } from "react";
import Intro from "@/img/video/seeding.mp4";
import { ReactComponent as On } from "@/img/Artiside/on.svg";
import { ReactComponent as Off } from "@/img/Artiside/off.svg";

function ArtisideCard(props) {
  const offset = props.offset;
  const isMobile = props.isMobile;
  const pageInfo = props.pageInfo;
  const screenInfo = props.screenInfo;
  const sceneInfo = {
    page: {
      startY: pageInfo.startY,
      endY: pageInfo.startY + pageInfo.height,
    },
  };

  const [artisideMuted, setArtisideMuted] = useState(true);
  const artisideToggleMuted = () => {
    setArtisideMuted((artisideMuted) => !artisideMuted);
  };

  const card = {
    maxWidth: isMobile
      ? "267px"
      : screenInfo.innerWidth <= 1280
      ? "429px"
      : "500px",
    maxHeight: isMobile
      ? "335px"
      : screenInfo.innerWidth <= 1280
      ? "530px"
      : "620px",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(245, 245, 245, 1)",
    borderRadius: isMobile ? "20px" : "30px",
    boxShadow: "9px 9px 16px rgba(137, 137, 137, 0.16), -9px -9px 16px #FFFFFF",
    padding: isMobile ? "14px" : "28px",
    marginTop: isMobile ? "-25px" : "0px",
    position: "relative",
  };

  const cardImg = {
    width: "100%",
    height: "100%",
    // background: `url(${image})`,
    backgroundSize: "cover",
    borderRadius: "20px",
  };

  return (
    <div style={card}>
      <div style={cardImg}>
        <button
          className="muted-btn muted-btn__artiside"
          onClick={() => artisideToggleMuted()}
        >
          {artisideMuted ? <On /> : <Off />}
          <p className="muted-info muted-info__artiside">
            {artisideMuted ? "Sound on" : "Sound off"}
          </p>
        </button>
        <video
          className="video-player"
          src={Intro}
          autoPlay
          muted={
            artisideMuted ||
            offset < sceneInfo.page.startY ||
            offset > sceneInfo.page.endY
          }
          loop
          playsInline
          style={{ width: "100%", height: "100%", borderRadius: "20px" }}
        >
          <source src={Intro} type="video/mp4" />
        </video>
      </div>
    </div>
  );
}

export default ArtisideCard;
