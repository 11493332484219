import React, { useState, useEffect } from "react";
import "@/pages/BackgroundSection/Roadmap/Roadmap.css";

export default function Roadmap({ offset, totalLength, pageInfo, screenInfo }) {
  const [tab, setTab] = useState("2024");
  const [percent, setPercent] = useState(0);
  const [isTablet, setIsTablet] = useState(false); // get tablet size(770px >= size <= 1248px)

  const heightValue = {
    // Left side of roadmap section in tablet size
    height:
      offset > pageInfo.startY && offset < totalLength
        ? isTablet
          ? percent > 50
            ? "100%"
            : `${Number(percent) * 2}%`
          : `${percent}%`
        : "0%",
  };

  const heightValue2 = {
    // Right side of roadmap section in tablet size
    display: isTablet ? (percent > 50 ? "block" : "none") : "none",
    height:
      offset > pageInfo.startY && offset < totalLength
        ? isTablet
          ? percent > 50
            ? `${((Number(percent) - 50) / 50) * 100}%`
            : "0%"
          : "0%"
        : "0%",
    transitionDelay:
      offset > pageInfo.startY && offset < totalLength ? "1.3s" : "0s",
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia(
      "screen and (max-width: 1248px) and (min-width: 770px)"
    );
    const handleScreenChange = (mq) => setIsTablet(mq.matches);

    setIsTablet(mediaQuery.matches);
    mediaQuery.addEventListener("change", handleScreenChange);

    return () => {
      mediaQuery.removeEventListener("change", handleScreenChange);
    };
  }, []);

  useEffect(() => {
    // Convert date to percentage
    const now = new Date();
    const yearStart = new Date(now.getFullYear(), 0, 0);
    const diff = now - yearStart;
    const oneDay = 1000 * 60 * 60 * 24;
    const dayOfYear = Math.floor(diff / oneDay);
    const percentOfYear = ((dayOfYear + 1) / 365) * 100;
    setPercent(percentOfYear.toFixed(2));
  }, []);

  const sceneInfo = {
    page: {
      startY: pageInfo.startY,
      endY: pageInfo.startY + pageInfo.height,
    },
  };

  return (
    <section
      className={
        offset >= sceneInfo.page.startY && offset < sceneInfo.page.endY
          ? "roadmap translate-up"
          : "roadmap"
      }
    >
      <div className="roadmap-table">
        <div className="roadmap-title">
          <p className="lexend-reg">Roadmap</p>
          <p>We have a solid plan to implement successful business</p>
        </div>
      </div>

      <div className="roadmap-btns">
        <button
          className={tab === "2023" ? "active" : ""}
          onClick={() => setTab("2023")}
        >
          2023
        </button>
        <button
          className={tab === "2024" ? "active" : ""}
          onClick={() => setTab("2024")}
        >
          2024
        </button>
        <button
          className={tab === "2025" ? "active" : ""}
          onClick={() => setTab("2025")}
        >
          2025
        </button>
      </div>

      {tab === "2023" ? (
        <div className="roadmap-contents table-in">
          <div className="roadmap-contents-4q">
            <ul>
              <li>Q1</li>
              <li>Art de Finance Official Site Launching</li>
              <li>Token Sale</li>
              <li>Gallery Partnership</li>
            </ul>

            <ul>
              <li>Q2</li>
              <li>Partnership Artist Onboarding</li>
              <li>White paper</li>
              <li>Token Generation</li>
              <li>
                NoS Service (v1) Launching
                <br />
                <span className="roadmap-contents-small">
                  (NFT Certification)
                </span>
              </li>
              <li>ADF PARTNERS Service Launching</li>
            </ul>

            <ul>
              <li>Q3</li>
              <li>THE FLUX Service (v1) Launching</li>
              <li>Artiside Service (v1) Launching</li>
              <li>
                Community Reward Program
                <br />
                <span className="roadmap-contents-small">(Fair Drop)</span>
              </li>
              <li>ADF Partner Artists onboarding</li>
              <li>
                Partnered Artists exhibition with
                <br />
                Seoul Metropolitan government
                <br />
                and Seoul Design Foundation
              </li>
              <li>ADF user onboarding</li>
            </ul>

            <ul>
              <li>Q4</li>
              <li>Artiside 'Seeding program' Launching</li>
              <li>Art Alliance establishment</li>
              <li>
                Global Partnership
                <br />
                <span className="roadmap-contents-small">
                  (Artists, Gallery, Exchanges, Projects)
                </span>
              </li>
              <li>
                Metaverse exhibition
                <br />
                with Seoul Metropolitan Government
              </li>
              <li>ADF Partnered Artists exhibition</li>
              <li>Incentive Testnet Bug Bounty</li>
            </ul>
          </div>

          <div className="roadmap-contents-2q">
            <article className="left-2q">
              <ul>
                <li>Q1</li>
                <li>Art de Finance Official Site Launching</li>
                <li>Token Sale</li>
                <li>Gallery Partnership</li>
              </ul>

              <ul>
                <li>Q2</li>
                <li>Partnership Artist Onboarding</li>
                <li>White paper</li>
                <li>Token Generation</li>
                <li>
                  NoS Service (v1) Launching
                  <br />
                  <span className="roadmap-contents-small">
                    (NFT Certification)
                  </span>
                </li>
                <li>ADF PARTNERS Service Launching</li>
              </ul>
            </article>

            <article className="right-2q">
              <ul>
                <li>Q3</li>
                <li>THE FLUX Service (v1) Launching</li>
                <li>Artiside Service (v1) Launching</li>
                <li>
                  Community Reward Program
                  <br />
                  <span className="roadmap-contents-small">(Fair Drop)</span>
                </li>
                <li>ADF Partner Artists onboarding</li>
                <li>
                  Partnered Artists exhibition with
                  <br />
                  Seoul Metropolitan government
                  <br />
                  and Seoul Design Foundation
                </li>
                <li>ADF user onboarding</li>
              </ul>

              <ul>
                <li>Q4</li>
                <li>Artiside 'Seeding program' Launching</li>
                <li>Art Alliance establishment</li>
                <li>
                  Global Partnership
                  <br />
                  <span className="roadmap-contents-small">
                    (Artists, Gallery, Exchanges, Projects)
                  </span>
                </li>
                <li>
                  Metaverse exhibition
                  <br /> with Seoul Metropolitan Government
                </li>
                <li>ADF Partnered Artists exhibition</li>
                <li>Incentive Testnet Bug Bounty</li>
              </ul>
            </article>
          </div>
        </div>
      ) : (
        <></>
      )}
      {tab === "2024" ? (
        <div className="roadmap-contents table-in">
          <div className="roadmap-contents-4q">
            <div
              className="roadmap-progress"
              style={{
                width:
                  offset > pageInfo.startY && offset < totalLength
                    ? `${percent}%`
                    : 0,
              }}
            >
              <div className="roadmap-progress-circle" />
            </div>
            <ul
              className={
                offset >= sceneInfo.page.startY && offset < sceneInfo.page.endY
                  ? "translate-up"
                  : ""
              }
            >
              <li>Q1</li>
              <li>Multi Wallet integration</li>
              <li>
                ADF PARTNERS Update
                <br />
                <span className="roadmap-contents-small">
                  (Admin function, Artists/Artwork <br />
                  Probation)
                </span>
              </li>
              <li>Artist Launchpad Launching</li>
              <li>Artiside UX/UI Update</li>
            </ul>

            <ul
              className={
                offset >= sceneInfo.page.startY && offset < sceneInfo.page.endY
                  ? "translate-up"
                  : ""
              }
            >
              <li>Q2</li>
              <li>ADF Vault Service Launching</li>
              <li>Governance Protocol Launching</li>
            </ul>

            <ul
              className={
                offset >= sceneInfo.page.startY && offset < sceneInfo.page.endY
                  ? "translate-up"
                  : ""
              }
            >
              <li>Q3</li>
              <li>Red Label Launching</li>
              <li>Art Vault Subscription</li>
              <li>ADF Explorer Development</li>
              <li>Referral program</li>
              <li>Art de Finance Mobile APP</li>
            </ul>

            <ul
              className={
                offset >= sceneInfo.page.startY && offset < sceneInfo.page.endY
                  ? "translate-up"
                  : ""
              }
            >
              <li>Q4</li>
              <li>Multichain Integration</li>
              <li>ADF Wallet Development</li>
              <li>"Stake to Advertise" Launching</li>
            </ul>
          </div>

          <div className="roadmap-contents-2q">
            <div
              className={
                offset > pageInfo.startY && offset < totalLength
                  ? "roadmap-progress long"
                  : "roadmap-progress"
              }
              style={heightValue}
            >
              <div
                className={
                  offset > pageInfo.startY && offset < totalLength
                    ? isTablet
                      ? percent > 50
                        ? "roadmap-progress-circle fade-out"
                        : "roadmap-progress-circle"
                      : "roadmap-progress-circle"
                    : "roadmap-progress-circle"
                }
              />
            </div>
            <article className="left-2q">
              <ul
                className={
                  offset >= sceneInfo.page.startY &&
                  offset < sceneInfo.page.endY
                    ? "translate-up"
                    : ""
                }
              >
                <li>Q1</li>
                <li>Multi Wallet integration</li>
                <li>
                  ADF PARTNERS Update
                  <br />
                  <span className="roadmap-contents-small">
                    (Admin function, Artists/Artwork <br />
                    Probation)
                  </span>
                </li>
                <li>Artist Launchpad Launching</li>
                <li>Artiside UX/UI Update</li>
              </ul>

              <ul
                className={
                  offset >= sceneInfo.page.startY &&
                  offset < sceneInfo.page.endY
                    ? "translate-up"
                    : ""
                }
              >
                <li>Q2</li>
                <li>ADF Vault Service Launching</li>
                <li>Governance Protocol Launching</li>
              </ul>
            </article>

            <article className="right-2q">
              <div
                className={
                  offset > pageInfo.startY && offset < totalLength
                    ? "roadmap-progress long"
                    : "roadmap-progress"
                }
                style={heightValue2}
              >
                <div
                  className={
                    offset > pageInfo.startY && offset < totalLength
                      ? isTablet
                        ? percent > 50
                          ? "roadmap-progress-circle fade-in"
                          : "roadmap-progress-circle"
                        : "roadmap-progress-circle"
                      : "roadmap-progress-circle"
                  }
                />
              </div>
              <ul
                className={
                  offset >= sceneInfo.page.startY &&
                  offset < sceneInfo.page.endY
                    ? "translate-up"
                    : ""
                }
              >
                <li>Q3</li>
                <li>Red Label Launching</li>
                <li>Art Vault Subscription</li>
                <li>ADF Explorer Development</li>
                <li>Referral program</li>
                <li>Art de Finance Mobile APP</li>
              </ul>

              <ul
                className={
                  offset >= sceneInfo.page.startY &&
                  offset < sceneInfo.page.endY
                    ? "translate-up"
                    : ""
                }
              >
                <li>Q4</li>
                <li>Multichain Integration</li>
                <li>ADF Wallet Development</li>
                <li>"Stake to Advertise" Launching</li>
              </ul>
            </article>
          </div>
        </div>
      ) : (
        <></>
      )}

      {tab === "2025" ? (
        <div className="roadmap-contents table-in">
          <div className="roadmap-contents-4q">
            <ul>
              <li>Q1</li>
              <li>Seeding V2.0 Launching</li>
              <li>Artiside Service (v2) Launching</li>
              <li>THE FLUX Service (v2) Launching</li>
            </ul>
            <ul>
              <li>Q2</li>
              <li>ADF DEX Service Launching</li>
              <li>Physical Art Onboarding</li>
            </ul>
            <ul>
              <li>Q3</li>
              <li>Art Financing Service (v1) Launching</li>
            </ul>
            <ul>
              <li>Q4</li>
              <li>Art Financing Service (v2) Launching</li>
              <li>Art de Finance Mainnet Launching</li>
            </ul>
          </div>

          <div className="roadmap-contents-2q">
            <article className="left-2q">
              <ul>
                <li>Q1</li>
                <li>Seeding V2.0 Launching</li>
                <li>Artiside Service (v2) Launching</li>
                <li>THE FLUX Service (v2) Launching</li>
              </ul>
              <ul>
                <li>Q2</li>
                <li>ADF DEX Service Launching</li>
                <li>Physical Art Onboarding</li>
              </ul>
            </article>
            <article className="right-2q">
              <ul>
                <li>Q3</li>
                <li>Art Financing Service (v1) Launching</li>
              </ul>
              <ul>
                <li>Q4</li>
                <li>Art Financing Service (v2) Launching</li>
                <li>Art de Finance Mainnet Launching</li>
              </ul>
            </article>
          </div>
        </div>
      ) : (
        <></>
      )}
    </section>
  );
}
