import React, { useEffect, useState } from "react";
import "@/components/DotIndicator/dot-indicator.css";

function DotIndicator(props) {
  const isMobile = props.isMobile;

  const [isPageNumber, setIsPageNumber] = useState(1);

  useEffect(() => {
    setIsPageNumber(props.pageNumber);
  }, [props.pageNumber]);

  const wrapper = {
    display: isMobile ? "none" : "flex",
    position: "absolute",
    right: "46px",
    top: "50%",
    flexDirection: "column",
    gap: "10px",
    zIndex: "2",
  };

  return (
    <div style={wrapper}>
      <div className={isPageNumber === 1 ? "dot active-dot" : "dot"}></div>
      <div className={isPageNumber === 2 ? "dot active-dot" : "dot"}></div>
      <div className={isPageNumber === 3 ? "dot active-dot" : "dot"}></div>
    </div>
  );
}

export default DotIndicator;
