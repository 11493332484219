import React from "react";
import "@/components/Card/Card.css";

export function Card({ data }) {
  const tagRegExp = new RegExp("<s*[^>]*>", "g");

  return data.map((item, i) => {
    return (
      <div className="card" key={i}>
        <div className="card-top">
          <img src={item.thumbnail} alt="카드 이미지" />
        </div>
        <div className="card-bottom">
          <a target="_blank" rel="noreferrer" href={item.link}>
            <p className="card-title">{item.title}</p>
          </a>
          <p className="card-text">{item.content.replace(tagRegExp, "")}</p>
        </div>
        <div className="card-footer">
          <p>{item.pubDate.substr(0, 10)}</p> {/* date format 2023-03-16 */}
        </div>
      </div>
    );
  });
}
