export default function TheFluxLogo({ isWhite }) {
  return (
    <svg
      width="93"
      height="17"
      viewBox="0 0 93 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M83.9116 8.10059L88.4116 3.60059"
        stroke={isWhite ? "#FFFFFF" : "#888888"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M85.4116 3.60059H88.4116V6.60059"
        stroke={isWhite ? "#FFFFFF" : "#888888"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.32691 10.234H2.17323V3.76404H0.490723V3H7.01754V3.76404H5.33503V10.234H5.32691Z"
        fill={isWhite ? "#FFFFFF" : "#222222"}
      />
      <path
        d="M12.2347 3H15.3883V10.234H12.2347V6.82831H10.7147V10.234H7.56104V3H10.7147V6.06427H12.2347V3Z"
        fill={isWhite ? "#FFFFFF" : "#222222"}
      />
      <path
        d="M22.2998 6.21058V6.97461H19.203V9.47805H22.4136V10.2421H17.6262C17.5611 10.2421 17.4473 10.2421 17.2767 10.2258C17.106 10.2177 16.9353 10.1933 16.7402 10.1689C16.5451 10.1445 16.3907 10.1039 16.2525 10.0551C16.1143 10.0064 16.0493 9.94135 16.0493 9.86007V3.38202C16.0493 3.30074 16.1143 3.23571 16.2525 3.18694C16.3907 3.13818 16.5533 3.09754 16.7402 3.07315C16.9271 3.04877 17.106 3.03251 17.2767 3.01626C17.4473 3.00813 17.5611 3 17.6262 3H22.4136V3.76404H19.203V6.21058H22.2998Z"
        fill={isWhite ? "#FFFFFF" : "#222222"}
      />
      <path
        d="M28.4233 3.11032C28.1225 3.12658 27.8055 3.15909 27.4723 3.20786C27.139 3.25663 26.8464 3.32165 26.6026 3.41106C26.3587 3.50047 26.2368 3.62239 26.2368 3.76056V15.9933H31.8695V10.3443H36.6082V8.98689H31.8695V4.44332H36.8195V3.08594H29.0491C28.9353 3.08594 28.724 3.09407 28.4314 3.11845L28.4233 3.11032Z"
        fill={isWhite ? "#FFFFFF" : "#222222"}
      />
      <path
        d="M43.4125 3.08594H37.7798V15.3186C37.7798 15.4649 37.9017 15.5787 38.1455 15.6681C38.3894 15.7576 38.682 15.8307 39.0152 15.8713C39.3485 15.9201 39.6655 15.9526 39.9662 15.9689C40.267 15.9851 40.4702 16.0014 40.584 16.0014H48.3544V14.644H43.4044V3.08594H43.4125Z"
        fill={isWhite ? "#FFFFFF" : "#222222"}
      />
      <path
        d="M72.6784 3.08594V8.4098L66.2491 9.66965C66.1678 9.68591 66.0296 9.71029 65.8183 9.75093C65.607 9.79157 65.3875 9.84034 65.1599 9.89723C64.9242 9.95413 64.7292 10.0273 64.5666 10.1004C64.404 10.1736 64.3228 10.2711 64.3228 10.3687V16.0014H69.9555V10.6775L76.3848 9.41768C76.466 9.40142 76.6042 9.37704 76.8156 9.3364C77.0188 9.29576 77.2463 9.24699 77.4739 9.1901C77.7096 9.1332 77.9047 9.06005 78.0673 8.98689C78.2298 8.91374 78.3111 8.81621 78.3111 8.71867V3.08594H72.6784Z"
        fill={isWhite ? "#FFFFFF" : "#222222"}
      />
      <path
        d="M77.7414 10.6926C77.4976 10.6032 77.205 10.5382 76.8717 10.4894C76.5385 10.4407 76.2215 10.4081 75.9208 10.3919C75.62 10.3756 75.4168 10.3594 75.303 10.3594H72.4907V15.9921H78.1235V11.0421C78.1235 10.904 78.0015 10.782 77.7577 10.6926H77.7414Z"
        fill={isWhite ? "#FFFFFF" : "#222222"}
      />
      <path
        d="M64.8884 8.38542C65.1322 8.47483 65.4248 8.54798 65.7581 8.58862C66.0913 8.63739 66.4083 8.6699 66.709 8.68616C67.0098 8.70241 67.213 8.71867 67.3268 8.71867H70.1391V3.08594H64.5063V8.03591C64.5063 8.18222 64.6283 8.29601 64.8721 8.38542H64.8884Z"
        fill={isWhite ? "#FFFFFF" : "#222222"}
      />
      <path
        d="M65.7573 9.10132C65.7573 9.10132 64.7332 9.10945 63.8635 8.79245C63.4165 8.62989 63.132 8.19098 63.132 7.71142V5.15109L63.1401 3.07031H57.5074C57.4992 4.65528 57.4911 6.24025 57.4911 7.83334C57.4911 8.38605 57.865 8.88186 58.4015 8.99565C58.8566 9.09319 59.2305 9.09319 59.2305 9.09319C58.816 9.13383 58.4665 9.23137 58.182 9.36142C57.7593 9.55649 57.4911 9.97915 57.4911 10.4424V14.6284H54.9633V3.08657H49.3306V15.3193C49.3306 15.4656 49.4525 15.5794 49.6963 15.6688C49.9402 15.7582 50.2328 15.8313 50.566 15.872C50.8993 15.9207 51.2163 15.9533 51.517 15.9695C51.8177 15.9858 52.0209 16.002 52.1347 16.002H60.2872C60.3928 16.002 60.5879 15.9939 60.8886 15.9695C61.1894 15.9533 61.5064 15.9207 61.8477 15.872C62.1891 15.8232 62.4817 15.7582 62.7337 15.6688C62.9857 15.5794 63.0995 15.4656 63.0995 15.3193C63.1238 14.669 63.132 14.0188 63.1157 13.3604C63.0995 12.702 63.0995 12.0437 63.0995 11.4016V10.7188C63.0995 10.3693 63.2539 10.0279 63.5303 9.81659C63.9854 9.47521 64.6763 9.19073 65.7248 9.10945L65.7573 9.10132Z"
        fill={isWhite ? "#FFFFFF" : "#222222"}
      />
    </svg>
  );
}
