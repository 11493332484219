import React from "react";
import Background from "@/img/Artiside/background.png";
import ArtisideCard from "@/pages/Artiside/ArtisideCard.js";
import DotIndicator from "components/DotIndicator/dot-indicator";
import { ReactComponent as Slogan } from "@/img/Artiside/Slogan.svg";
import { ReactComponent as MobileSlogan } from "@/img/Artiside/mobile-slogan.svg";
import Logo from "@/img/Artiside/artiside_logo.js";
import ArtisideMobileTextarea from "@/sections/artiside/artiside-mobile-textarea";
import "@/pages/Artiside/Artiside.css";
import Arrow from "components/Icons/Arrow";

function Artiside(props) {
  const pageNumber = 1;
  const { offset, refProp, pageInfo, screenInfo, isMobile } = props;

  const sceneInfo = {
    page: {
      startY: pageInfo.startY,
      endY: pageInfo.startY + pageInfo.height,
    },
  };

  const section = {
    position: "sticky",
    top: "0",
    height: "100vh",
    backgroundColor: "white",
    backgroundImage: `url(${Background})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "80% 10%",
    overflowX: "hidden",
    objectFit: "cover",
    transition: "0.3s",
    zIndex:
      offset > sceneInfo.page.startY &&
      offset <= sceneInfo.page.startY + pageInfo.height
        ? 1
        : 0,
    opacity:
      offset <= sceneInfo.page.startY ||
      offset < sceneInfo.page.startY + pageInfo.height
        ? 1
        : 0,
  };

  const sectionContainer = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "1300px",
    maxHeight: isMobile
      ? "600px"
      : screenInfo.innerWidth <= 1280
      ? "800px"
      : "1080px",
    height: "100%",
    margin: isMobile
      ? "0px auto 0"
      : screenInfo.innerWidth <= 1280
      ? "60px auto 0"
      : "0 auto",
    padding: "0 3rem",
    position: "relative",
  };

  const artisideSlogan = {
    position: "absolute",
    top: isMobile
      ? "0px"
      : screenInfo.innerWidth <= 1280
      ? "0px"
      : screenInfo.innerHeight <= 900
      ? "-20px"
      : "40px",
    left: isMobile ? "50%" : screenInfo.innerWidth <= 1280 ? "50%" : "80px",
    transform:
      screenInfo.innerWidth <= 1280 ? "translateX(-50%)" : "translateX(0%)",
    width: isMobile
      ? "250px"
      : screenInfo.innerWidth <= 1280
      ? "390px"
      : "680px",
    zIndex: "1",
    transition: "0.5s",
  };

  const typography = {
    lineHeight: "34px",
    fontSize: isMobile ? "16px" : "18px",
    fontWeight: "400",
    maxWidth: "290px",
    width: "100%",
    color: "#333333",
    marginTop: "130px",
  };

  return (
    <section style={section} ref={refProp}>
      <div className="artiside-container" style={{ ...sectionContainer }}>
        {isMobile || screenInfo.innerWidth <= 1280 ? (
          <MobileSlogan style={artisideSlogan} />
        ) : (
          <Slogan className="123" style={artisideSlogan} />
        )}
        <div
          style={{
            ...typography,
            // ...isMobileDelete,
            marginTop: "290px",
            marginRight: "80px",
            textAlign: "right",
            display: screenInfo.innerWidth <= 1280 ? "none" : "block",
            lineHeight: "29px",
            letterSpacing: "1px",
          }}
        >
          <p>
            Have you discovered captivating
            <br />
            artworks? Have you come across
            <br />
            an artist with immense potential?
            <br />
            If so, it's time to support them
            <br />
            through <b>'Seeding.'</b>
            <br />
            <br />
            Your support will help artists
            <br />
            maintain stable artistic activities
            <br />
            and foster a nurturing
            <br />
            artistic environment.
          </p>
        </div>
        <ArtisideCard
          isMobile={isMobile}
          pageInfo={pageInfo}
          screenInfo={screenInfo}
          offset={offset}
        />
        <div
          style={{
            ...typography,
            // ...isMobileDelete,
            marginTop: "100px",
            marginLeft: "80px",
            textAlign: "left",
            display: screenInfo.innerWidth <= 1280 ? "none" : "block",
            lineHeight: "29px",
            letterSpacing: "1px",
          }}
        >
          Seeding on Artiside goes beyond
          <br />a <b>simple patronage system.</b>
          <br />
          You can also earn rewards while
          <br />
          supporting artists.
          <br />
          <br />
          This is only possible through
          <br />
          Seeding on Artiside.
          <br />
          <br />
          Join us on Artiside and
          <br />
          <b>let's Seed together!</b>
          <div className="artiside-button-wrap">
            <a
              className="artiside-button"
              href="https://artiside.artdefinance.io/seeding/about"
              target="_blank"
              rel="noreferrer"
            >
              About Seeding program <Arrow />
            </a>
            <a
              className="artiside-button"
              href="https://artiside.artdefinance.io/seeding"
              target="_blank"
              rel="noreferrer"
            >
              Go to Seeding program <Arrow />
            </a>
          </div>
        </div>
        <Logo isMobile={isMobile} screenInfo={screenInfo} />
        <ArtisideMobileTextarea isMobile={isMobile} screenInfo={screenInfo} />
      </div>
      <DotIndicator isMobile={isMobile} pageNumber={pageNumber} />
    </section>
  );
}

export default Artiside;
