import * as React from "react";
import "@/components/SimpleAccordion/SimpleAccordion.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { faqData } from "@/data/faqData";
import { Box } from "@mui/material";

function SimpleAccordion() {
  const data = faqData.rows;

  const CustomExpandIcon = () => {
    return (
      <Box
        sx={{
          ".Mui-expanded & > .collapsIconWrapper": {
            display: "none",
          },
          ".expandIconWrapper": {
            display: "none",
          },
          ".Mui-expanded & > .expandIconWrapper": {
            display: "block",
          },
        }}
      >
        <div className="expandIconWrapper">-</div>
        <div className="collapsIconWrapper">+</div>
      </Box>
    );
  };

  return data.map((a, i) => {
    return (
      <Accordion className="transparent-bg" key={i}>
        <AccordionSummary
          expandIcon={<CustomExpandIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          key={i}
        >
          <Typography className="faq-title faq-question">Q.</Typography>
          <Typography className="faq-title">{a.title}</Typography>
        </AccordionSummary>
        <AccordionDetails className="faq-bottom">
          <Typography className="faq-content">{a.content}</Typography>
        </AccordionDetails>
      </Accordion>
    );
  });
}

export default SimpleAccordion;
