import React from "react";
import "@/pages/BackgroundSection/Background.css";
import Roadmap from "@/pages/BackgroundSection/Roadmap/Roadmap";
import Community from "@/pages/BackgroundSection/Community/Community";
import Faq from "@/pages/BackgroundSection/Faq/Faq";
import Partners from "@/pages/BackgroundSection/Partners/Partners";

export default function Background({ offset, refProp, pageInfo, screenInfo }) {
  let totalLength = document.body.scrollHeight;
  const sceneInfo = {
    page: {
      startY: pageInfo.startY,
      endY: pageInfo.startY + pageInfo.height * 3,
    },
  };

  return (
    <section
      className="background-section"
      ref={refProp}
      style={{
        width: "100%",
        top: 0,
        left: 0,
        opacity: offset > sceneInfo.page.startY && offset < totalLength ? 1 : 0,
        transition: "opacity 0.5s",
        marginTop: "50vh",
      }}
    >
      <Roadmap
        totalLength={totalLength}
        offset={offset}
        pageInfo={pageInfo}
        screenInfo={screenInfo}
      />
      <Community
        totalLength={totalLength}
        offset={offset}
        pageInfo={pageInfo}
        screenInfo={screenInfo}
      />
      <Faq
        totalLength={totalLength}
        offset={offset}
        pageInfo={pageInfo}
        screenInfo={screenInfo}
      />
      <Partners
        totalLength={totalLength}
        offset={offset}
        pageInfo={pageInfo}
        screenInfo={screenInfo}
      />
    </section>
  );
}
