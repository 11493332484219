import React from "react";
import "@/components/Footer/Footer.css";
import { ReactComponent as FooterLogo } from "@/img/common/logo.svg";

export default function Footer({ refProp }) {
  return (
    <section className="footer" ref={refProp}>
      <div className="footer-container">
        <div className="footer-left">
          <FooterLogo />
          <p>© 2024. Art de Finance. All Rights Reserved</p>
        </div>
        <div className="footer-right">
          <div className="footer-contact">
            <p className="lexend-reg">Contact Us</p>
            <a className="mail" href="mailto:business@artdefinance.io">
              business@artdefinance.io
            </a>
          </div>
          <a
            className="chainlink"
            href="https://automation.chain.link/polygon/16658488834653047630317612310624919329282922426306827068709008694114758203853"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="https://chain.link/badge-automation-black"
              alt="automation secured with chainlink"
            />
          </a>
        </div>
      </div>
    </section>
  );
}
