import React, { useEffect, useRef } from "react";
import "@/pages/ThreePages/common.css";
import "@/pages/ThreePages/Contents/Contents.css";
import Bg from "@/img/4.contents/v2/background2.png";
import Frame from "@/img/4.contents/v2/contents.png";
import Frame2 from "@/img/4.contents/v2/mobile-contents.png";
import Img2 from "@/img/4.contents/contents-bg.png";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Splitting from "splitting";

gsap.registerPlugin(ScrollTrigger);

function Contents({ offset, refProp, pageInfo, screenInfo, isMobile }) {
  const textRef = useRef(null);
  const sceneInfo = {
    page: {
      startY: pageInfo.startY,
      startOpacity: pageInfo.startY + pageInfo.height,
      endY: pageInfo.startY + pageInfo.height,
    },
  };

  useEffect(() => {
    const texts = Splitting({ target: textRef.current, by: "chars" }); // 한글자씩 분리

    const timeline = gsap.timeline({
      default: { duration: 0.6, ease: "power2.inOut" },
      scrollTrigger: {
        trigger: textRef.current,
        start: sceneInfo.page.startY + screenInfo.innerHeight * 0.33,
        end: sceneInfo.page.startY + screenInfo.innerHeight * 0.98,
        toggleActions: "play none none reset",
        scrub: true,
      },
    });

    texts.forEach((text) => {
      const chars = text.chars;
      timeline.from(
        chars,
        { willChange: "opacity", opacity: 0.4, stagger: 0.03 },
        "-=0.55"
      );
      timeline.to(
        chars,
        { willChange: "opacity", opacity: 1, stagger: 0.03 },
        "-=0.55"
      );
    });

    return () => {
      timeline.kill();
    };
  }, [sceneInfo.page.startY, screenInfo.innerHeight]);

  return (
    <div
      className="section-wrapper"
      ref={refProp}
      style={{
        position: "sticky",
        top: 0,
        left: 0,
        opacity:
          offset > sceneInfo.page.startY &&
          offset <= sceneInfo.page.startY + pageInfo.height
            ? 1
            : 0,
        zIndex:
          offset > sceneInfo.page.startY &&
          offset <= sceneInfo.page.startY + pageInfo.height
            ? 1
            : 0,
        transitionDelay: "opacity 1s",
      }}
    >
      <img src={Bg} className="page3-bg" alt="백그라운드" />
      <section className="page3-section page3-section__contents">
        <div className="page3-wrapper__contents">
          <div className="content-text content-title-text">
            <div className="title-section title-section__contents content-effect">
              <div
                className={
                  offset >= sceneInfo.page.startY &&
                  offset < sceneInfo.page.endY
                    ? "web-page3-text title-effect title-effect__contents"
                    : "web-page3-text"
                }
              >
                <div className="text-top__contents">
                  <p
                    className="text-title text-title__contents"
                    style={{
                      color: "rgba(102, 102, 102, 1)",
                      fontSize: isMobile ? "14px" : "16px",
                    }}
                  >
                    Point 3 &nbsp;
                    <span
                      style={{
                        color: "rgba(187, 187, 187, 1)",
                        fontSize: isMobile ? "16px" : "18px",
                      }}
                    >
                      Contents
                    </span>
                  </p>
                  <p className="lexend-reg">
                    Making
                    <span className="scotch-italic-font text-accent__contents">
                      {" "}
                      Appreciation
                    </span>
                    &nbsp;and
                  </p>
                </div>
                <div className="text-bottom__contents">
                  <p className="lexend-reg">
                    <span
                      className="lexend-sb text-accent__contents"
                      style={{ letterSpacing: "-1px" }}
                    >
                      {" "}
                      Understanding
                    </span>
                    &nbsp;easier
                  </p>
                </div>
              </div>

              <div
                className={
                  offset >= sceneInfo.page.startY &&
                  offset < sceneInfo.page.endY
                    ? "mobile-size-text title-effect title-effect__contents"
                    : "mobile-size-text"
                }
              >
                <div className="mobile-text-start">
                  <div className="text-top__contents">
                    <p
                      className="text-title text-title__contents"
                      style={{
                        fontSize: "16px",
                        color: "rgba(187, 187, 187, 1)",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "14px",
                          color: "rgba(102, 102, 102, 1)",
                        }}
                      >
                        Point 3 &nbsp;
                      </span>
                      Contents
                    </p>
                    <p className="lexend-reg">
                      Making
                      <br />
                      <span className="scotch-italic-font text-accent__contents">
                        {" "}
                        Appreciation{" "}
                      </span>
                      and
                    </p>
                  </div>
                </div>
                <div className="mobile-text-end">
                  <div className="text-bottom text-bottom__contents">
                    <p className="lexend-reg">
                      <span
                        className="lexend-sb text-accent__contents"
                        style={{
                          fontSize: isMobile ? "38px" : "70px",
                          fontWeight: "500",
                        }}
                      >
                        {" "}
                        Understanding{" "}
                      </span>
                      <br />
                      easier
                    </p>
                  </div>
                </div>
              </div>
              {/* 모바일 텍스트 */}
              {screenInfo.innerWidth <= 1400 ? (
                <div
                  className={
                    offset >= sceneInfo.page.startY &&
                    offset < sceneInfo.page.endY
                      ? "mobile-text mobile-text__contents title-effect title-effect_contents"
                      : "mobile-text mobile-text__contents"
                  }
                >
                  <p>
                    Art de Finance will create appealing <br />
                    multimedia content for the public to <br />
                    aid in understanding and digesting the <br />
                    professional knowledge provided by <br />
                    experts from galleries and art museums.
                  </p>
                </div>
              ) : null}
              {/* 웹 텍스트 */}
              <div
                style={{ display: isMobile ? "none" : "block" }}
                className={
                  offset >= sceneInfo.page.startY &&
                  offset < sceneInfo.page.endY
                    ? "sub-text__contents sub-effect sub-effect__contents"
                    : "sub-text__contents"
                }
              >
                <p ref={textRef}>
                  Art de Finance will create appealing <br />
                  multimedia content for the public to <br />
                  aid in understanding and digesting the <br />
                  professional knowledge provided by <br />
                  experts from galleries and art museums.
                </p>
              </div>
            </div>
          </div>

          <div
            className={
              offset >= sceneInfo.page.startY && offset < sceneInfo.page.endY
                ? "page3-imgwrapper page3-imgwrapper__contents img-effect__contents"
                : "page3-imgwrapper page3-imgwrapper__contents"
            }
          >
            <img className="contents-articles" src={Img2} alt="백그라운드" />
            <img
              src={isMobile ? Frame2 : Frame}
              alt="액자"
              className="page3-frameimg page3-frameimg__contents"
              style={{ width: "100%", top: isMobile ? "75px" : "0" }}
            />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Contents;
