export default function PartnersLogo({ isWhite }) {
  return (
    <svg
      width="152"
      height="16"
      viewBox="0 0 152 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.42981 9.83231L6.81377 4.66211H7.949L11.2741 15.3004H10.1389L9.1117 12.0145L9.00204 11.6637H8.63447H3.6308H3.26324L3.15357 12.0145L2.12642 15.3004H0.99117L4.31627 4.66211H5.4515L3.83546 9.83231L3.63255 10.4815H4.31269H7.95258H8.63272L8.42981 9.83231Z"
        fill={isWhite ? "#FFFFFF" : "#222222"}
        stroke={isWhite ? "#FFFFFF" : "#222222"}
      />
      <path
        d="M29.1121 10.9702H28.6121V11.4702V15.3163H27.4299V6.86816H28.6121V9.28801V9.78801H29.1121H35.1491V10.9702H29.1121Z"
        fill={isWhite ? "#FFFFFF" : "#222222"}
        stroke={isWhite ? "#FFFFFF" : "#222222"}
      />
      <path
        d="M28.9421 5.85115V4.66895H35.1944V5.85115H28.9421Z"
        fill={isWhite ? "#FFFFFF" : "#222222"}
        stroke={isWhite ? "#FFFFFF" : "#222222"}
      />
      <path
        d="M14.1423 13.3915V6.60596H15.3211V13.3915H14.1423Z"
        fill={isWhite ? "#FFFFFF" : "#222222"}
        stroke={isWhite ? "#FFFFFF" : "#222222"}
      />
      <path
        d="M16.3174 5.60911V4.5498H19.4771C22.3629 4.5498 24.7021 6.88922 24.7021 9.77477V10.2163C24.7021 13.1021 22.3629 15.4413 19.4771 15.4413H16.3174V14.3949H19.4345C21.7623 14.3949 23.649 12.5079 23.649 10.1801V9.82358C23.649 7.49582 21.7622 5.60911 19.4345 5.60911H16.3174Z"
        fill={isWhite ? "#FFFFFF" : "#222222"}
        stroke={isWhite ? "#FFFFFF" : "#222222"}
      />
      <path
        d="M98.9381 12.7862L99.8499 14.1109V12.5027V4.69385H101.029V15.3133H99.5126L93.9421 7.22093L93.0303 5.89627V7.50444V15.3133H91.8516V4.69385H93.3678L98.9381 12.7862Z"
        fill={isWhite ? "#FFFFFF" : "#222222"}
        stroke={isWhite ? "#FFFFFF" : "#222222"}
      />
      <path
        d="M61.4955 9.85136L59.8794 4.68115H61.0147L64.3398 15.3194H63.2045L62.1776 12.0336L62.068 11.6827H61.7004H56.6965H56.3289L56.2192 12.0335L55.1921 15.3194H54.0568L57.3819 4.68115H58.5172L56.9011 9.85136L56.6982 10.5005H57.3784H61.0183H61.6984L61.4955 9.85136Z"
        fill={isWhite ? "#FFFFFF" : "#222222"}
        stroke={isWhite ? "#FFFFFF" : "#222222"}
      />
      <path
        d="M44.5965 12.157V15.3118H43.4143V4.69385H48.885L48.8852 4.6941H49.0922C51.0149 4.6941 52.5736 6.25275 52.5736 8.17568C52.5736 9.13718 52.1845 10.0068 51.5539 10.6374C50.9234 11.268 50.0537 11.657 49.0922 11.657H45.0965H44.5965V12.157ZM48.9822 10.7171C49.6058 10.7171 50.178 10.492 50.6201 10.1192H50.6335L50.7799 9.97268C51.2392 9.51313 51.5242 8.87734 51.5242 8.17543C51.5242 6.77135 50.3859 5.63347 48.9822 5.63347H45.0965H44.5965V6.13347V10.2171V10.7171H45.0965H48.9822Z"
        fill={isWhite ? "#FFFFFF" : "#222222"}
        stroke={isWhite ? "#FFFFFF" : "#222222"}
      />
      <path
        d="M131.603 4.51975C133.778 4.3634 135.149 5.1543 136.037 6.22784L135.18 6.53728C134.588 5.80583 133.669 5.26568 132.298 5.26516C130.302 5.24984 129.511 6.18911 129.394 6.35408L129.394 6.35407C128.678 7.36594 129.151 8.7029 130.346 9.01998C130.467 9.05906 130.635 9.08859 130.796 9.11294C130.982 9.14112 131.209 9.1692 131.452 9.19661C131.855 9.24195 132.318 9.28695 132.745 9.32841C132.834 9.33705 132.922 9.34554 133.007 9.35385L133.007 9.35385C133.894 9.44049 134.752 9.54164 135.38 9.94027C136.002 10.3351 136.44 10.923 136.596 11.6017L136.596 11.6017C136.717 12.1314 136.649 12.6766 136.476 13.156C136.302 13.641 136.037 14.0184 135.816 14.2137L135.816 14.2137L135.813 14.2166C135.081 14.8771 134.042 15.3727 132.545 15.4802L132.545 15.4802C131.092 15.5848 130.013 15.2661 129.202 14.7467C128.545 14.3265 128.041 13.7604 127.654 13.1354L128.662 12.7718C128.901 13.2224 129.232 13.6592 129.691 14.0105C130.304 14.4794 131.11 14.77 132.148 14.77C132.851 14.77 133.724 14.5717 134.411 14.1379C135.112 13.6947 135.678 12.955 135.533 11.9182C135.454 11.3571 135.144 10.962 134.757 10.7044C134.384 10.4567 133.941 10.3347 133.549 10.2752L133.549 10.2752C133.192 10.2212 132.848 10.1764 132.51 10.1325C131.94 10.0583 131.388 9.98648 130.822 9.87618L130.822 9.87615C130.111 9.73764 129.465 9.58971 128.948 9.25471C128.442 8.92639 128.104 8.40395 128.018 7.83137L128.018 7.83129C127.892 6.99494 128.05 6.4153 128.311 5.9998C128.577 5.57724 128.979 5.27721 129.418 5.06053L129.422 5.05838C129.76 4.8974 130.139 4.76475 130.566 4.66896H130.566L130.619 4.65771C130.922 4.5923 131.249 4.54516 131.603 4.51975Z"
        fill={isWhite ? "#FFFFFF" : "#222222"}
        stroke={isWhite ? "#FFFFFF" : "#222222"}
      />
      <path
        d="M105.247 13.6372V14.1372H105.747H112.499V15.3194H105.747H104.064V4.68115H105.747H112.499V5.86335H105.747H105.247V6.36335V8.9093V9.4093H105.747H111.784V10.5913H105.747H105.247V11.0913V13.6372Z"
        fill={isWhite ? "#FFFFFF" : "#222222"}
        stroke={isWhite ? "#FFFFFF" : "#222222"}
      />
      <path
        d="M76.0544 10.5003H76.6826V15.3194H75.5004V11.9981V11.4981H75.0004H69.1964H68.6964V11.9981V15.3194H67.5142V4.68115H73.5799C75.2012 4.68115 76.5156 5.99551 76.5156 7.61688C76.5156 8.40804 76.2034 9.12483 75.6943 9.65343L74.8787 10.5003H76.0544ZM72.4011 5.6795V5.67901H71.9011H69.1961H69.1959H68.6959V6.17901V10.0005V10.5005H69.1959H73.1941H73.206L73.2179 10.5C73.8389 10.4703 74.3989 10.2064 74.81 9.79533C75.2467 9.3587 75.5175 8.75403 75.5175 8.08716C75.5175 6.75067 74.4337 5.6795 73.1021 5.6795H72.4011Z"
        fill={isWhite ? "#FFFFFF" : "#222222"}
        stroke={isWhite ? "#FFFFFF" : "#222222"}
      />
      <path
        d="M123.948 10.5003H124.577V15.3194H123.394V11.9981V11.4981H122.894H117.09H116.59V11.9981V15.3194H115.408V4.68115H121.474C123.095 4.68115 124.41 5.99551 124.41 7.61688C124.41 8.40804 124.097 9.12483 123.588 9.65343L122.773 10.5003H123.948ZM120.295 5.6795V5.67901H119.795H117.09H117.09H116.59V6.17901V10.0005V10.5005H117.09H121.088H121.1L121.112 10.5C121.733 10.4703 122.293 10.2064 122.704 9.79533C123.141 9.3587 123.412 8.75403 123.412 8.08716C123.412 6.75067 122.328 5.6795 120.996 5.6795H120.295Z"
        fill={isWhite ? "#FFFFFF" : "#222222"}
        stroke={isWhite ? "#FFFFFF" : "#222222"}
      />
      <path
        d="M83.6792 6.36335V5.86335H83.1792H79.6895V4.68115H88.8509V5.86335H85.3614H84.8614V6.36335V15.3194H83.6792V6.36335Z"
        fill={isWhite ? "#FFFFFF" : "#222222"}
        stroke={isWhite ? "#FFFFFF" : "#222222"}
      />
      <path
        d="M142.912 8.10059L147.412 3.60059"
        stroke={isWhite ? "#FFFFFF" : "#888888"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M144.412 3.60059H147.412V6.60059"
        stroke={isWhite ? "#FFFFFF" : "#888888"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
