import React, { useEffect, useRef, useState } from "react";
import "@/pages/First/First.css";
import frame from "@/img/1.main/v2/change/frame.png";
import Intro from "@/img/video/intro_movie.mp4";
import DotIndicator from "components/DotIndicator/dot-indicator";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import { ReactComponent as SoundOn } from "@/img/1.main/sound_on.svg";
import { ReactComponent as SoundOff } from "@/img/1.main/sound_off.svg";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Splitting from "splitting";

gsap.registerPlugin(ScrollTrigger);

function First({ offset, refProp, pageInfo, screenInfo, isMobile }) {
  const pageNumber = 2;
  const sceneInfo = {
    page: { startY: pageInfo.startY, endY: pageInfo.startY + pageInfo.height },
    frame:
      screenInfo.innerWidth <= 800
        ? { scale: 0.1, endTrnaslateY: 10 }
        : screenInfo.innerWidth >= 1280 && screenInfo.innerHeight <= 1080
        ? { scale: 0.17, endTrnaslateY: 80 }
        : { scale: 0.17, endTrnaslateY: 84 },
  };

  return (
    <div
      className="section-lss"
      ref={refProp}
      style={{
        position: "sticky",
        opacity: offset <= sceneInfo.page.endY ? 1 : 0,
        top: 0,
        left: 0,
        overflow: "hidden",
        height: "100vh",
        transition: "0.3s",
        zIndex: offset <= sceneInfo.page.endY ? 1 : 0,
      }}
    >
      <section className="contents-section">
        <div
          className="first-img"
          style={{ marginTop: isMobile ? "-5px" : "0px" }}
        >
          <Frame
            offset={offset}
            screenInfo={screenInfo}
            sceneInfo={sceneInfo}
            isMobile={isMobile}
          />
        </div>
        <DotIndicator
          offset={offset}
          pageInfo={pageInfo}
          screenInfo={screenInfo}
          isMobile={isMobile}
          pageNumber={pageNumber}
        />
      </section>
    </div>
  );
}

function Frame({ offset, screenInfo, sceneInfo, isMobile }) {
  const lssTitleRef = useRef(null);
  const textRef = useRef(null);
  const text2Ref = useRef(null);

  const [muted, setMuted] = useState(true);
  const toggleMuted = () => {
    setMuted((muted) => !muted);
  };

  const titleText = {
    fontSize:
      screenInfo.innerWidth > 1400 ? "74px" : isMobile ? "35px" : "60px",
  };

  const subTitleTextEffect = {
    marginTop: isMobile ? "0" : "-120px",
  };

  useEffect(() => {
    // const titles = Splitting({ target: lssTitleRef.current, by: "chars" });
    const texts = Splitting({ target: textRef.current, by: "chars" }); // 한글자씩 분리
    const texts2 = Splitting({ target: text2Ref.current, by: "chars" });

    const timeline = gsap.timeline({
      default: { duration: 0.6, ease: "power2.inOut" },
      scrollTrigger: {
        trigger: textRef.current,
        start: "top 30%",
        end: "bottom 10%",
        toggleActions: "play none none reset",
        scrub: true,
      },
    });

    texts.forEach((text) => {
      const chars = text.chars;
      timeline.from(chars, { opacity: 0.4, stagger: 0.03 }, "-=0.55");
      timeline.to(chars, { opacity: 1, stagger: 0.03 }, "-=0.55");
    });

    const timeline2 = gsap.timeline({
      default: { duration: 0.6, ease: "power2.inOut" },
      scrollTrigger: {
        trigger: text2Ref.current,
        start: "top 80%",
        end: "bottom 20%",
        toggleActions: "play none none reset",
        scrub: true,
      },
    });

    texts2.forEach((text) => {
      const chars = text.chars;
      timeline.from(chars, { opacity: 0.4, stagger: 0.03 }, "-=0.55");
      timeline.to(chars, { opacity: 1, stagger: 0.03 }, "-=0.55");
    });

    return () => {
      // lssTitleTimeline.kill();
      timeline.kill();
      timeline2.kill();
    };
  }, []);

  return (
    <div className="frame">
      <div
        className="frame-wrapper2"
        style={{ width: "100%", height: "100%", overflow: "hidden" }}
      >
        <div
          className="framing"
          style={{ width: "100%", height: isMobile ? "667px" : "100%" }}
        >
          <div className="title-textarea" style={subTitleTextEffect}>
            <p
              className="scotch-italic-font"
              style={titleText}
              ref={lssTitleRef}
            >
              Starting with
            </p>
            <p className="scotch-italic-font" style={{ fontSize: "22px" }}>
              {screenInfo.innerWidth > 1400 ? (
                <span
                  className="scotch-italic-font"
                  style={{ fontSize: "48px" }}
                >
                  a new experience <br />
                  revival of art!
                </span>
              ) : (
                "a new experience revival of art!"
              )}
            </p>
          </div>
          <div className="frame-containers">
            <img src={frame} alt="액자" />
            <button className="muted-btn" onClick={() => toggleMuted()}>
              {muted ? <SoundOn /> : <SoundOff />}
              <p className="muted-info">{muted ? "Sound on" : "Sound off"}</p>
            </button>
            <video
              className="video-player"
              src={Intro}
              autoPlay
              muted={
                muted ||
                offset <= sceneInfo.page.startY ||
                offset > sceneInfo.page.endY
              }
              loop
              playsInline
            >
              <source src={Intro} type="video/mp4" />
            </video>
          </div>

          <div className="contents-textarea">
            <Swiper
              spaceBetween={50}
              slidesPerView={1}
              pagination={true}
              modules={[Pagination]}
              className="main-swiper"
              style={{ width: "350px" }}
            >
              <SwiperSlide>
                {" "}
                <div
                  className="main-sub-text main-left-text"
                  style={{ lineHeight: isMobile ? "28px" : "36px" }}
                >
                  <p ref={textRef} style={{ marginTop: "5px" }}>
                    Once the issue of time and space has been solved, which has
                    continued to be the biggest hurdle for the public when it
                    comes to enjoying art, a door of new possibilities will open
                    in the art industry.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                {" "}
                <div className="main-sub-text main-right-text">
                  <p ref={text2Ref}>
                    From experience to possession, from possession to
                    investment. That’s the digital renaissance that Art de
                    Finance seeks to achieve.
                  </p>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
}

export default First;
