export const faqData = {
  rows: [
    {
      title: "What kind of service does Art de Finance’s Artiside provide?",
      content:
        "Artiside is a digital art platform based on social curation. Artists and collectors, the two central axes of the art industry, will not be connected only through sales, but also by using the function of social media, artists will be able to easily showcase their works to collectors and collectors can meet more artists and works. Furthermore, the service will include artwork sales and sponsorship of artists.",
    },
    {
      title:
        "Please explain the types and methods of sponsoring artists through Artiside.",
      content:
        "Artiside's sponsorship is divided into two main categories: 'Seeding', a method of sponsoring the 'artist' itself, is a method of depositing a certain amount of donations into Artiside's sponsor product and delivering the interest generated through this as donation money, and 'Red Label', sponsors a specific 'work' of an artist. It is a way to directly support a certain amount in donations.",
    },
    {
      title: "What criteria do you use to sponsor artists?",
      content:
        "Sponsoring an artist is mostly decided on the basis of the collector's 'satisfaction from ownership' and 'return on investment'. Among the many factors that serve as the reason for this decision, Artiside faithfully shares the introduction of the artist and exposes the data of Seeding and Red Label to collectors so that they can be used as a foundation for determining sponsorship.",
    },
    {
      title:
        "I want to register as a digital artist at Artiside. What are the standards for artist registration?",
      content:
        "First and foremost, you can register as an ‘artist’ through ‘Pro mode’ (provisional title) under the status of a general user. After that, if you enter your information in the registration request form provided, we will provide an answer as to whether or not you have been registered upon an internal review.*A small amount of artist registration fee is incurred during the artist registration process.",
    },
    {
      title: "How can Seeding and Red Label help artists?",
      content:
        "Sponsorships generated through Seeding are regularly paid until the supporter ‘withdraws’ support, which leads to stable productivity and activity of the artists. So they are ways to support artists financially other than purchasing artwork. In addition, Red Label, a direct investment in artworks, can help to speed up the sale of artworks by acknowledging that the artworks are the most artistically sophisticated among many.",
    },
    {
      title: "What kind of service does Art de Finance provide?",
      content:
        "Art de Finance (hereinafter referred to as Art de Finance) provides services such as conducting live exhibition tours with viewers by visiting the most desired exhibition sites hand-picked by its community members.",
    },
    {
      title: "What is the most significant advantage of live exhibition tours?",
      content:
        "Above all, the greatest advantage lies in overcoming regional limitations and providing services in real-time. As a result, viewers of the live exhibition can vividly experience exhibitions being held on the opposite side of the world and synchronously communicate with the exhibition hosts such as artists and gallerists. Thus, the public can gain a greater appreciation and understanding of art, and artists and galleries can reach a wider audience.",
    },
    {
      title: "How can I use ADF tokens in the services stated above?",
      content:
        "Artiside's ADF token is used as a registration fee when changing the status from a general user to an artist and is typically used as a currency for collectors to sponsor and vote for artists and works. Rewards can be accepted through ADF token staking, and the voting power during the selection of exhibitions that the community members wish to visit the most will be based on the staking amount.",
    },
    {
      title: "How can I purchase artwork?",
      content:
        "Users who wish to purchase can pay with stablecoins and a certain portion of the purchase price will be accumulated as points in ADF tokens.",
    },
    {
      title:
        "What are Art de Finance’s exclusive premium storage and delivery services?",
      content:
        "Art de Finance provides premium storage and delivery services to users who purchase work through the platform and stake a certain amount of ADF tokens. The owner of the artwork can enjoy premium artwork storage service in a country of their choice by picking one of the many storage facilities across the globe from Art de Finance’s partnered storage service locations. In addition, if the owner wishes to have the work delivered, the delivery and installment can be handled by a delivery business that has been with the Art de Finance partner galleries for a long time. Users who are staking ADF tokens can expect greater benefits.",
    },
  ],
};
