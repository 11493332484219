export default function Logo({ color }) {
  return (
    <svg
      width="105"
      height="38"
      viewBox="0 0 105 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_372_193)">
        <path
          d="M68.1713 32.8114L59.9714 20.8989H59.8275H56.7039V21.1074V37.5581H59.8275V25.6453L68.0275 37.5581H68.1713H71.295V37.3493V20.8989H68.1713V32.8114Z"
          fill={color ? color : "#CCCCCC"}
        />
        <path
          d="M33.2133 32.8114L25.0133 20.8989H24.8695H21.7458V21.1074V37.5581H24.8695V25.6453L33.0694 37.5581H33.2133H36.3369V37.3493V20.8989H33.2133V32.8114Z"
          fill={color ? color : "#CCCCCC"}
        />
        <path
          d="M65.6713 2.95264H62.5476V14.1149H65.6713V2.95264Z"
          fill={color ? color : "#CCCCCC"}
        />
        <path
          d="M76.1828 8.27778V8.7891C76.1828 11.7303 73.7987 14.1147 70.8575 14.1147H65.6714V17.0487H70.9182C75.4515 17.0487 79.126 13.3742 79.126 8.84089V8.20785C79.126 3.67491 75.4515 0 70.9182 0H65.6714V2.95246H70.8575C73.7987 2.95246 76.1828 5.33653 76.1828 8.27778Z"
          fill={color ? color : "#CCCCCC"}
        />
        <path
          d="M19.1987 20.8989H16.0701V37.5848H19.1987V20.8989Z"
          fill={color ? color : "#CCCCCC"}
        />
        <path
          d="M46.5094 20.8989L49.1172 29.242H43.8987L46.5064 20.8989H43.3765L38.1614 37.5848H41.2913L42.9209 32.3706H50.0949L51.7246 37.5848H54.8542L49.639 20.8989H46.5094Z"
          fill={color ? color : "#CCCCCC"}
        />
        <path
          d="M3.12862 24.0376H0V37.5833H3.12862V31.3521H12.5006V28.2235H3.12862V24.0376Z"
          fill={color ? color : "#CCCCCC"}
        />
        <path
          d="M13.5263 20.8999H3.12866V24.0285H13.5263V20.8999Z"
          fill={color ? color : "#CCCCCC"}
        />
        <path
          d="M104.269 24.0275V20.8989H93.872H90.7434V37.5848H93.872H104.269V34.4562H93.872V30.8063H103.244V27.6777H93.872V24.0275H104.269Z"
          fill={color ? color : "#CCCCCC"}
        />
        <path
          d="M23.4293 11.6402L25.0593 16.8543H28.1889L22.9737 0.168457H19.8438L22.4515 8.51155H17.2331L19.8408 0.168457H16.7112L11.4961 16.8543H14.6257L16.2553 11.6402H23.4293Z"
          fill={color ? color : "#CCCCCC"}
        />
        <path
          d="M94.4662 16.8543V13.7257H84.0686V10.0755H93.4409V6.94724H84.0686V3.29708H94.4662V0.168457H84.0686H80.9399V16.8543H84.0686H94.4662Z"
          fill={color ? color : "#CCCCCC"}
        />
        <path
          d="M33.1315 11.3756H41.4528V16.854H44.5814V8.51122H42.964C43.8174 7.62548 44.3422 6.42108 44.3422 5.09428C44.3422 2.37373 42.137 0.168457 39.4164 0.168457H30.0032V16.8543H33.1318V11.3759L33.1315 11.3756ZM37.0096 3.03284V3.0335H38.7316C40.2484 3.0335 41.4779 4.25176 41.4779 5.76857C41.4779 6.52697 41.1704 7.21346 40.6736 7.71059C40.2055 8.1787 39.5695 8.47823 38.8635 8.51155H33.1315V3.03251H37.0096V3.03284Z"
          fill={color ? color : "#CCCCCC"}
        />
        <path
          d="M85.9149 31.6551C85.8523 31.8698 85.779 32.0786 85.6936 32.2812C85.6177 32.4613 85.5336 32.6352 85.4429 32.8001C85.4429 32.8001 84.8685 34.023 83.8687 34.631C83.1584 35.0809 82.274 35.3099 81.2392 35.3099C80.1298 35.3099 79.1949 35.0476 78.4602 34.5307C77.7219 34.0118 77.1582 33.1686 76.7847 32.2815C76.4047 31.3803 76.2612 30.357 76.2612 29.242C76.2612 28.127 76.4044 27.1037 76.7847 26.2021C77.1582 25.3154 77.7223 24.4719 78.4602 23.9526C79.1949 23.436 80.1301 23.1741 81.2392 23.1741C82.3482 23.1741 83.2835 23.436 84.0184 23.9526C84.7237 24.4491 85.2703 25.2415 85.6431 26.0853C85.655 26.112 85.6669 26.1388 85.6777 26.1655L88.557 25.1174C88.4818 24.9485 88.4013 24.7836 88.3155 24.6223C87.6198 23.3123 86.6476 22.2834 85.4254 21.5649C84.2055 20.8477 82.7972 20.4839 81.2395 20.4839C79.6818 20.4839 78.2725 20.8477 77.0526 21.5649C75.8307 22.2831 74.8585 23.312 74.1625 24.6223C73.4694 25.9263 73.1184 27.4807 73.1184 29.242C73.1184 31.0032 73.4697 32.5573 74.1625 33.8617C74.8582 35.172 75.8307 36.2009 77.0526 36.9187C78.2722 37.6362 79.6805 38.0001 81.2395 38.0001C82.7985 38.0001 84.2058 37.6362 85.4254 36.9187C85.6075 36.8118 85.7859 36.6967 85.9558 36.5763C86.8729 36.0132 87.6501 34.9173 87.6501 34.9173C87.8936 34.5897 88.1169 34.2348 88.3155 33.861C88.5464 33.4266 88.7394 32.9641 88.8938 32.4758L85.9796 31.4152C85.9595 31.4957 85.938 31.5759 85.9149 31.6551Z"
          fill={color ? color : "#CCCCCC"}
        />
        <path
          d="M51.8851 16.8537H55.0138V3.29675H60.7336V0.168457H46.1653V3.29675H51.8851V16.8537Z"
          fill={color ? color : "#CCCCCC"}
        />
      </g>
      <defs>
        <clipPath id="clip0_372_193">
          <rect width="104.269" height="38" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
