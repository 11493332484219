import React from "react";
import "@/components/TopButton/TopButton.css";
import { ReactComponent as TopArrow } from "@/img/TopButton/top-arrow.svg";

function TopButton() {
  return (
    <section className="top-button__container">
      <a className="top-button" href="/#">
        <TopArrow />
      </a>
    </section>
  );
}

export default TopButton;
