import React, { useState, useEffect, useRef } from "react";
import "@/components/Navbar/Navbar.css";
import { ReactComponent as Logo } from "@/img/common/logo.svg";
import HamburgerMenu from "components/Icons/HamburgerMenu";
import MobileNavMenu from "./MobileNavMenu";
import ArtisideLogo from "img/NavBar/ArtisideLogo";
import PartnersLogo from "img/NavBar/PartnersLogo";
import TheFluxLogo from "img/NavBar/TheFluxLogo";

function Navbar(props) {
  const { offset, pageInfo, isWhite } = props;
  const sceneInfo = {
    page: {
      startY: pageInfo.startY,
      endY: pageInfo.startY + pageInfo.height,
    },
  };
  const [isNavVisible, setIsNavVisible] = useState(true);
  const [prevScrollTop, setPrevScrollTop] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);
  const headerRef = useRef(null);

  useEffect(() => {
    function handleScroll() {
      const nextScrollTop = window.pageYOffset || 0;
      const isScrollingDown = nextScrollTop > prevScrollTop;

      setIsNavVisible(!isScrollingDown || nextScrollTop < 100);

      setPrevScrollTop(nextScrollTop);
    }

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollTop]);

  const headerClass = isNavVisible ? "" : "scrollDown";

  return (
    <header ref={headerRef} className={headerClass}>
      <div className="navbar-container">
        <Logo />
        <ul
          className="navbar-sitemap"
          style={{
            color: offset <= sceneInfo.page.endY ? "black" : "white",
          }}
        >
          <li
            onClick={() => {
              window.open("https://artiside.artdefinance.io");
            }}
          >
            <ArtisideLogo isWhite={isWhite} />
          </li>
          <li
            onClick={() => {
              window.open("https://theflux.artdefinance.io");
            }}
          >
            <TheFluxLogo isWhite={isWhite} />
          </li>
          <li
            onClick={() => {
              window.open("https://partners.artdefinance.io");
            }}
          >
            <PartnersLogo isWhite={isWhite} />
          </li>
        </ul>
        <button
          className="navbar-mobile-button"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <HamburgerMenu
            open={menuOpen}
            color={offset <= sceneInfo.page.endY ? "#222222" : "white"}
          />
        </button>
        <MobileNavMenu open={menuOpen} onClick={() => setMenuOpen(!menuOpen)} />
      </div>
    </header>
  );
}

export default Navbar;
