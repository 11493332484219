import React, { useEffect, useRef } from "react";
import "@/pages/ThreePages/common.css";
import "@/pages/ThreePages/Experience/Experience.css";
import Frame from "@/img/2.experience/frame.png";
import { ReactComponent as Line80 } from "@/img/2.experience/before-line.svg";
import { ReactComponent as Line40 } from "@/img/2.experience/before-line2.svg";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Splitting from "splitting";

gsap.registerPlugin(ScrollTrigger);

function Experience({ offset, refProp, pageInfo, isMobile, screenInfo }) {
  const textRef = useRef(null);
  const sceneInfo = {
    page: {
      startY: pageInfo.startY,
      endY: pageInfo.startY + pageInfo.height,
    },
  };

  useEffect(() => {
    const texts = Splitting({ target: textRef.current, by: "chars" });

    const timeline = gsap.timeline({
      default: { duration: 0.6, ease: "power2.inOut" },
      scrollTrigger: {
        trigger: textRef.current,
        start: sceneInfo.page.startY + screenInfo.innerHeight * 0.33,
        end: sceneInfo.page.startY + screenInfo.innerHeight * 0.98,
        toggleActions: "play none none reset",
        scrub: true,
      },
    });

    texts.forEach((text) => {
      const chars = text.chars;
      timeline.from(
        chars,
        { willChange: "opacity", opacity: 0.4, stagger: 0.03 },
        "-=0.55"
      );
      timeline.to(
        chars,
        { willChange: "opacity", opacity: 1, stagger: 0.03 },
        "-=0.55"
      );
    });

    return () => {
      timeline.kill();
    };
  }, [sceneInfo.page.startY, screenInfo.innerHeight]);

  return (
    <div
      className="section-wrapper"
      ref={refProp}
      style={{
        position: "sticky",
        top: 0,
        left: 0,
        opacity:
          offset > sceneInfo.page.startY &&
          offset <= sceneInfo.page.startY + pageInfo.height
            ? 1
            : 0,
        zIndex:
          offset > sceneInfo.page.startY &&
          offset <= sceneInfo.page.startY + pageInfo.height
            ? 1
            : 0,
        transitionDelay: "opacity 1s",
      }}
    >
      <section className="page3-section page3-section__experience">
        <div className="page3-wrapper__experience">
          <div
            className={
              offset >= sceneInfo.page.startY && offset < sceneInfo.page.endY
                ? "page3-imgwrapper page3-imgwrapper__experience img-effect"
                : "page3-imgwrapper page3-imgwrapper__experience"
            }
          >
            <img
              className="page3-frameimg page3-frameimg__experience"
              src={Frame}
              alt="액자"
              style={{ marginTop: isMobile ? "30px" : "0px" }}
            />
          </div>
          <div className="exp-text exp-title-text">
            <div
              className={
                offset >= sceneInfo.page.startY && offset < sceneInfo.page.endY
                  ? "title-section exp-effect title-effect title-effect__experience"
                  : "title-section exp-effect"
              }
            >
              <div
                className={
                  offset >= sceneInfo.page.startY &&
                  offset < sceneInfo.page.endY
                    ? "web-title title-effect title-effect__experience"
                    : "web-title"
                }
              >
                <div className="text-top__experience">
                  <p
                    className="text-title text-title__experience"
                    style={{
                      color: "rgba(102, 102, 102, 1)",
                      fontSize: isMobile ? "14px" : "16px",
                    }}
                  >
                    Point 1 &nbsp;
                    <span
                      style={{
                        color: "rgba(187, 187, 187, 1)",
                        fontSize: isMobile ? "16px" : "18px",
                      }}
                    >
                      Experience
                    </span>
                  </p>
                  <p className="scotch-font">
                    <span className="scotch-font text-accent">Experience</span>{" "}
                    art
                  </p>
                </div>
                <div className="text-bottom text-bottom__experience">
                  <Line80 className="before-line" />
                  <Line40 className="before-line2" />
                  <p className="scotch-font">on a broader scale</p>
                </div>
              </div>
              {/* 모바일 텍스트 */}
              {screenInfo.innerWidth <= 1400 ? (
                <div
                  className={
                    offset >= sceneInfo.page.startY &&
                    offset < sceneInfo.page.endY
                      ? "mobile-text mobile-text__experience title-effect title-effect__experience"
                      : "mobile-text mobile-text__experience"
                  }
                  style={{ marginTop: "40px" }}
                >
                  <p>
                    Real-time gallery exhibition tours will be made possible{" "}
                    <br />
                    through live streaming. A team of producers made of <br />
                    experts will live stream the exhibition that the audience{" "}
                    <br />
                    selected through voting, and they will be communicating{" "}
                    <br />
                    with the viewers, enabling a completely new exhibition{" "}
                    <br />
                    experience.
                  </p>
                </div>
              ) : null}
              {/* 웹 텍스트 */}
              <div
                className={
                  offset >= sceneInfo.page.startY &&
                  offset < sceneInfo.page.endY
                    ? "sub-text__experience sub-effect sub-effect__experience"
                    : "sub-text__experience"
                }
                style={{
                  marginTop: "18%",
                  display: isMobile ? "none" : "block",
                }}
              >
                <p ref={textRef}>
                  Real-time gallery exhibition tours will be
                  <br /> made possible through live streaming. <br />A team of
                  producers made of experts will <br />
                  live stream the exhibition that the <br />
                  audience selected through voting, <br />
                  and they will be communicating with the <br />
                  viewers, enabling a completely new
                  <br />
                  exhibition experience.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Experience;
