import React from "react";
import ReactDOM from "react-dom/client";
import TagManager from "react-gtm-module";
import App from "@/App";
import reportWebVitals from "@/reportWebVitals";

const tagManagerArgs = {
  gtmId: "G-26KG3EYZT4",
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
